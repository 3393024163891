:root, html, body {
    color: #35414c;
    font-size: 1rem;
    font-family: 'Noto Sans KR', sans-serif;
}

html {
    overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Gothic A1', sans-serif;
}

a {
    text-decoration: none;
    color: #35414c;
 }

 * {
     box-sizing: border-box;
 }